import React from 'react';
import './Vision.css';

const Vision = () => {
  return (
    <section id="vision" className="vision-section">
      <h2>Our Vision</h2>
      <div class="vision-content">
      <div className="vision-image">
            <img src="our_vision.jpeg" alt="Lipstick and Makeup" />
          </div>
          <div class="vision-text">
              <p>We believe beauty is about celebrating individuality. Our vision is to empower people everywhere with the tools to express themselves through makeup. By harnessing the power of AI, we aim to provide <span class="highlight">personalized, inclusive, and insightful beauty</span> solutions that cater to the diversity of skin tones, facial structures, and preferences.</p>
          </div>
      </div>
    </section>
  );
};

export default Vision;
