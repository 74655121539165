import React from 'react';
import './App.css';
import Header from './components/Header';
import Vision from './components/Vision';
import Services from './components/Services';
import ContactInfo from './components/ContactInfo';

function App() {
  return (
    <div className="main-wrapper"> {/* Added a wrapper for overall layout */}
      <div className="App">
        <Header />
        <div className="site-container">
          <main>
            <section id="home" className="intro-section">
              <div className="content">
                <h1>Welcome to <span className="brand-name">BeAnAina</span></h1>
                <h2><span className="gradient-text">Your AI Makeup Assistant</span>!</h2>
                <p>
                  Our innovative platform helps you discover the perfect makeup suggestions tailored to your unique features. Whether it's your skin tone, face shape, or personal style, <span className="brand-name">BeAnAina</span> uses advanced AI to offer makeup tips and product recommendations that enhance your natural beauty. Say goodbye to guesswork and hello to effortless beauty routines designed just for you!
                </p>
                <button className="try-button">Try Now</button>
              </div>
              <div className="image-container">
                <img 
                  src="makeup_assistant.jpeg" 
                  alt="AI Makeup Assistant" 
                  className="assistant-image frame-12"
                />            
              </div>
            </section>
            <Vision />
            <Services />
          </main>
        </div>
      </div>
      <ContactInfo />
    </div>
  );
}

export default App;
