import React, { useState } from 'react';
import './Header.css';

function Header() {
  const [active, setActive] = useState('home');  // Default active item

  return (
    <header className="header">
      <div className="logo-section">
        <img src="logo.png" className="logo" alt="Logo" />
      </div>

      <nav className="nav-section">
        <ul>
          <li>
            <a 
              href="#home" 
              className={active === 'home' ? 'active' : ''}
              onClick={() => setActive('home')}
            >
              Home
            </a>
          </li>
          <li>
            <a 
              href="#vision" 
              className={active === 'vision' ? 'active' : ''}
              onClick={() => setActive('vision')}
            >
              Vision
            </a>
          </li>
          <li>
            <a 
              href="#services" 
              className={active === 'services' ? 'active' : ''}
              onClick={() => setActive('services')}
            >
              Services
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;