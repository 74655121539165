import React, { useState, useEffect } from 'react';
import './ContactInfo.css';

function ContactInfo() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  const backToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="contact-info">
      <button
        id="back-to-top"
        onClick={backToTop}
        aria-label="Back to Top"
        className={isVisible ? 'show' : 'hide'}
      >
        &#x2191; {/* Unicode arrow up */}
      </button>

      <div className="contact-details">
        <div className="contact-item">
          <div className="contact-icon">
            <img src="locationImage.png" alt="Location icon" />
          </div>
          <div className="contact-text">
            <p>
              <strong>Address:</strong><br />
              A452, GM Infinite E-City Town Phase 1,<br />
              Thirupalya Main Rd, Thirupalya,<br />
              Bommasandra, Karnataka 560100<br/>
            </p>
          </div>
        </div>

        <div className="contact-item">
          <div className="contact-icon">
            <img src="email.png" alt="Email icon" />
          </div>
          <div className="contact-text">
            <p>
              <strong>Email:</strong><br />
              <a href="mailto:Info@atmobsolutions.com" className="contact-link">Info@atmobsolutions.com</a>
            </p>
          </div>
        </div>

        <div className="contact-item">
          <div className="contact-icon">
            <img src="phoneno.png" alt="Phone icon" />
          </div>
          <div className="contact-text">
            <p>
              <strong>Phone Number:</strong><br />
              <a href="tel:+919835825952" className="contact-link">+91 9835825952</a>
            </p>
          </div>
        </div>
      </div>

      <hr className="contact-separator" />

      <section className="footer-wrapper">
        <footer>
          <div className="footer-content">
            <span> ATMob Solutions Pvt Itd © 2024. All rights reserved.</span>
            <div className="social-icons">
              <a href="https://twitter.com/youraccount" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="https://instagram.com/youraccount" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="https://youtube.com/youraccount" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-youtube"></i>
              </a>
            </div>
          </div>
        </footer>
      </section>
    </div>
  );
}

export default ContactInfo;
