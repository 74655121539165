import React from 'react';
import './Services.css';

const Services = () => {
  return (
    <section id="services" className="services-section">
      <h2>Our Services</h2>
      <div className="service-content">
        <div className="service-text">
          <h3>Virtual Makeup Try-On</h3>
          <p>
            Transform your beauty routine with our AI-powered virtual makeup try-on tool. Effortlessly experiment in real-time with lipsticks, eyeshadows, and more. Discover makeup recommendations and find your perfect look – hassle-free and from the comfort of your home.
          </p>
          <a href="/try-now" className="try-button">Try It Now</a>
        </div>
        <div className="service-image">
          <img src="virtual_makeup_tryon.jpeg" alt="Virtual Makeup Try-On" />
        </div>
      </div>

      <div className="service-content">
      <div className="service-image">
          <img src="illuminate_your_skin.jpeg" alt=" Virtual Makeup Try-On" />
        </div>
        <div className="service-text">
          <h3>Illuminate Your Skin</h3>
          <p>
          Our advanced AI Skin Analysis tool detects various skin conditions like enlarged pores, acne, dark circles, and more with precision. It also provides age prediction and personalized skincare recommendations to enhance your routine. Experience an in-depth analysis designed to help you achieve radiant, healthy skin.
          </p>
          <a href="/try-now" className="try-button">Try It Now</a>
        </div>
      </div>

      <div className="service-content">
        <div className="service-text">
          <h3>Smart Mirror </h3>
          <p>
          Experience the future of beauty with our smart mirror feature. Get tips and recommendations as you see yourself reflected ensuring you look your best!
          </p>
          <a href="/service" className="try-button">Try It Now</a>
        </div>
        <div className="service-image">
          <img src="smart_mirror.jpeg" alt="Smart Mirror Try-On" />
        </div>
      </div>
    </section>
  );
};

export default Services;
